*html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  min-height: 100%; /* make sure it is at least as tall as the viewport */
}

#root {
  height: 100%; /* force the BODY element to match the height of the HTML element */
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.loginContainer {
  text-align: center;
  /* padding: 100px; */
  margin-top: 20vh;
}

.parent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
  width: 400px;
  height: 600px;
}
.main-div {
  margin: 50px 0px 50px 0px;
  width: 100%;
}

.vertical-center {
  margin: 0;
}

.round-border {
  border: 1px solid #e24809;
  border-radius: 25px 0px;
}

.Login-title {
  width: 100%;

  background-color: #e24809;
  border-radius: 0px 0px 0px 0px;
  color: #fff;
  padding: 10px;
  /* margin-left:-15px; */
}
.logo-container {
  padding: 25px 0px 25px 0px;
}
.welcome-text {
  color: #e24809;
  text-align: center;
}
.error-text-hide {
  display: none;
}

.error-text-show {
  margin: 10px 20px 10px 20px;
}

.show-inline {
  display: inline-block;
}
.error-text {
  color: red;
}
.title-text {
  text-align: center;
  color: #fff;
  font-size: 23px;
  padding: 0px;
  display: inline-block;
}
.label {
  color: #e24809;
}
input#exampleEmail,
input#examplePassword {
  width: 75%;
  margin: auto auto;
}

.custom-btn {
  background-color: #e24809;
  color: #fff;
  border: 0px;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  padding-top: 10p;
  margin-top: 15px;
}
.custom-btn:hover {
  background-color: #ff6426;
}

.main_container {
  width: 100%;
}
.inputFields {
  max-height: 65vh;

  /* overflow-y: auto; */
}

.select-dataset {
  font-weight: bold;
}

#main_row {
  margin-right: 0px;
  margin-left: 0px;
}
.updateInfo {
  white-space: normal;
  float: left;
}

.highlightText {
  color: #2fbabe;
  font-weight: 700;
}
.highlightText:hover {
  color: #2fbabe;
  text-decoration: underline;
}

.regularText {
  color: #737576;
}
.regularText:hover {
  color: #737576;
  text-decoration: none;
}

.downloadDivText {
  color: #737576;
  font-weight: 600;
}
.downloadDivText:hover {
  color: #2fbabe;
  text-decoration: none;
}

#left_col {
  padding-left: 0px;
  padding-right: 0px;
  height: 100%;

  border-right: 1px solid #e24809;
}
#right_col {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: auto;
}
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 25px;
  border-top: 1px solid #ccc;
  color: #e24809;
  line-height: 25px;
  padding: 0px 20px;
  background: #fff;
  font-size: 10px;
  z-index: 3;
}

/* @media (max-width: 1300px){
 
  .product_counter {
    width: 100%;
    clear: both;
    height: 54px;
  
    float: right;
    text-align: center;
  
    margin-top: -4px;
    padding-top: 12px;
    color: #02364c;
    font-weight: 500;
} 
   
}*/
/* @media (max-width: 767px) {
  .navitem_responsive{
    width:100%;
    font-size: 17px;
    border-top:1px dashed rgb(160, 160, 160);
  }
  .navitem_responsive:last-child{
    border-bottom:none;
  }
  .navitem-border {
    border-left: 0px;
  }

  .navlink-text{
    color: #2fbabe;
    
    margin-bottom:5px;
    text-decoration: none;
  }
  

  .nav-logo-holder{
    margin-left: 0px;
   }
   
   [id^=react-tabs]{
     width: 100%;
   }
   
  
   .search-button{
     display: none;
   }
   .search-button_toggled{
    display:none;
   }

   .grid_item1{
    box-sizing: border-box;
    width: 100vw;
    border-right: 1px solid #ccc;
    background: #fff;
    
   }
   .grid_item1_toggled{
    width: 100vw;
    
  }
   
   

m1  .main_info_div{
    width: 100vw;
    float: right;
    transition: 0.5s;
  }
  .main_info_div_toogled{
    width: 100vw;
    float: right;
    transition: 0.5s;
  }

  .main_info_div_toogled_one{
    width: 100vw;
    float: right;
    transition: 0.5s;
  }
  .tabs_responsive{
   
    width: 100%

  }

} */
.input-group-sm {
  width: 100%;
}

@media (max-width: 768px) {
.grid_item1{
 
  box-sizing: border-box;
    width: 100vw;
    border-right: 1px solid #ccc;
    background: #fff;

}
}

@media (min-width: 768px) {
  .tabs_responsive {
    float: left;
    width: 100%;
    margin-top: -1px;
  }
  .tab_contents {
    clear: both;
    float: left;
    width: 100%;
    /* margin-top: 1px; */
    border-top: 1px solid #e3e3e3;
  }
  .tab_list > ul {
    border-bottom: 0px;
    margin: 0px;
  }
  .tab_list > ul > li {
    float: left;
  }
  .grid_item1 {
    box-sizing: border-box;
    width: 25vw;

    height: 100%;
    padding: 0px;
    margin-right: 75vw;
    border-right: 1px solid #ccc;
    transition: 0.5s;
    background: #f8f9fa;
    height: 100%;
    position: absolute;
    float: left;
  }
  .grid_item1_toggled {
    box-sizing: border-box;
    height: 100%;
    padding: 0px;
    margin: 0px;
    border-right: 1px solid #ccc;
    transition: 0.5s;
    background: #f8f9fa;
    /* margin-right: 97vw; */
    width: 3vw;
    position: absolute;
    float: left;
  }
  .navitem-border {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  .slim_nav {
    border-bottom: 1px solid #ccc;
    height: 60px;
  }
  li.react-tabs__tab {
    color: #fff;
    border-right: 1.5px solid #01212f;
    /* border-top: 1px solid #02364c;
    border-bottom: 1px solid #02364c; */
    border-radius: 7px;
    border-bottom: 0px;
    border-radius: 0px;
    background: #02364c;
    display: flex;
    align-items: baseline;
  }
  li.react-tabs__tab:hover {
    background: #698e9f;
    color: #fff;
  }

  .react-tabs__tab.react-tabs__tab--selected::after {
    width: 0px;
    height: 0px;
  }
  li#react-tabs-0.react-tabs__tab.react-tabs__tab--selected::after {
    color: #ff0000;
  }
  li.react-tabs__tab.react-tabs__tab--selected {
    color: #02364c;
    border-right: 1.5px solid #01212f;
    border-radius: 7px;
    border-bottom: 1px solid transparent;
    border-radius: 0px;
    background: #fff;
    font-family: "Roboto", sans-serif;
  }

  li.react-tabs__tab.react-tabs__tab--selected > div > span.tab_header {
    /* text-decoration: underline; */
  }
  .react-tabs__tab-list {
    border-bottom: 0px;
  }


  .bi-x-circle {
  }


  div#form_tabs {
    border: 1px solid #e24809;
    /* margin-top: -1.5px; */
    padding-bottom: 5px;
    border-radius: 0px 0px 5px 5px;
    margin-top: -1px;
    background: #f1eae7;
  }
  .form_text {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #02354a;
  }
  #form_tabs > ul > li {
    font-size: 13px;
    width: 50%;
  }

  .form_container {
  }
  .extra_margin {
    margin-bottom: 65px;
  }

  .main_info_div {
    width: 60vw;
    transition: 0.5s;
    margin-left: 25vw;
  }
  .main_info_div_toogled {
    width: 97vw;
    transition: 0.5s;
    margin-left: 3vw;
  }

  .main_info_div_toogled_one {
    width: 82vw;
    transition: 0.5s;
    margin-left: 3vw;
  }
  .main_info_div_toogled_two {
    width: 75vw;
    transition: 0.5s;
    margin-left: 25vw;
  }
  .tab_container {
    /* margin-top: -3px; */
  }
}


.new-tab-button {
  width: 44px;
  height: 44px;
  padding: 5.7px 12px 8px 12px;
  background: #02354a;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  color: #fff;
  border: 0.5px solid #01212f;
  border-radius: 3px;
  /* border-left: 0.5px solid #e3e3e3; */
}

.new-tab-button:hover {
  background: #698e9f;
  color: #fff;
}

.checkbox_input_container {
  display: flex;
  align-items: center;
}

.navigation {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 3;
}
.dashboard {
  margin-top: 60px;
  left: 0;
  bottom: 25px;
  width: 100%;
}

.nav-logo-holder {
  margin-left: 100px;
}
.navlink-text {
  color: #737576;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  /* 
  color: #2fbabe; */
  /* text-shadow: 0px 1px #aeaeae; */
}

h4.navlink-text {
  margin: 0;
  padding-top: 0.3rem;
}

.navlink-text:hover {
  color: #e24809;
}

.tab_header {
  display: inline-block;
  position: relative;
  line-height: 30px;
  font-size: 15px;
  padding: 0 10px;
  font-weight: 400;
}

.tab_header_icon {
  display: inline-block;
  position: relative;
  line-height: 30px;
  font-size: 18px;
  padding: 0;
  font-weight: 400;
}

.tab_header_delete {
  display: inline-block;
  position: relative;
  line-height: 30px;
  font-size: 18px;
  padding: 0;
  font-weight: 800;
}
.tab_header_delete:hover {
  color: red;
}

/* search form */

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 21.5vw;
  border: 1px solid;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  /* padding-top: 6px;
  padding-left: 1px; */
  background: #02364c;
  transition: 0.5s;
}
.search-button:hover {
  cursor: pointer;
  border: 1px solid #02364c;
  background: #fff;
  color: #02364c;
}
.hover_effect {
  cursor: pointer;
  border: 1px solid #e24809;
  background: #fff;
  color: #e24809;
}
/* search_button_effect */
.walkthrough_search_div {
  width: 50%;
  background: transparent !important;
}

.search-button_toggled {
  width: 35px;
  height: 35px;
  margin-left: 1px;
  margin-top: 10px;
  border: 1px solid;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  /* padding-top: 4px;
    padding-left: 1px; */
  background: #02364c;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1400px) {
  .search-button_toggled {
    width: 40px;
    height: 40px;
    margin-left: 0.4vw;
    margin-top: 10px;
    border: 1px solid;
    border-radius: 51%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    /* padding-top:6px;
    padding-left:1px; */
    background: #02364c;
    transition: 0.5s;
  }
}

.toggled_button_hover {
  cursor: pointer;
  border: 1px solid #02364c;
  background: #fff;
  color: #02364c;
}

.search-button_toggled:hover {
  cursor: pointer;
  border: 1px solid #02364c;
  background: #fff;
  color: #02364c;
}
.search_form {
  position: absolute;
  width: 99%;
  margin: 20px auto 10px 1%;
  max-height: 80vh;
  padding-bottom: 300px; 
  overflow-y: scroll;
  overflow-x: hidden;
}
.show_form {
  display: block;
}
.search_form_toogled {
  margin: 10px auto 10px 1%;
  display: none;
}

.form_labels {
  font-size: 11px;
  float: left;
  /* margin-top:0.2rem; */
  margin-bottom: 0rem;
}
.form_input {
  width: 90%;
  float: left;
}
.form_label_pic {
  display: flex;
  width: 13%;
  height: 38px;
  background-color: #ebeced;
  border-left: 1px solid #02364c;
  border-top: 1px solid #02364c;
  border-bottom: 1px solid #02364c;
  border-radius: 0.25rem 0 0 0.25rem;
  /* position: absolute; */
}
.form_label_pic_in_half {
  width: 25%;
  height: 38px;
  background-color: #ebeced;
  border: 1px solid #02364c;
  border-right: 0px !important;
  border-radius: 0.25rem 0 0 0.25rem;
}

.form_lablel_img {
  /* height: 60%; */
  margin: auto auto;
  /* color: #737576; */
  color: #02354a;
}

.unchecked_icon {
  color: #9bb0b8;
}

.form_lablel_img_half {
  height: 60%;
  margin: auto auto;
  /* color: #737576; */
  color: #02354a;
}
.full_width {
  display: flex;
  width: 98%;
  padding-left: 2%;
  margin-bottom: 5px;
}

.dataset_inputfield {
  margin-top: 10px;
  margin-right: 10px;
}

/* 
.react-datepicker-wrapper{
  width: 100%;
}
.date_picker input[type=text]{
  padding-left: 10px !important;
  border-color:#f2ae92 !important;
  outline: none;
} */

.full_width > input {
  width: 88%;
}
.half_width {
  width: 49%;
  float: left;
  padding-left: 2%;
  margin-bottom: 5px;
}
.half_width_chk {
  width: 46%;
  float: left;
  padding-left: 3%;
  margin-bottom: 5px;
}

.half_width_last_item {
  width: 49%;
  float: left;
  padding-left: 1%;
  margin-bottom: 5px;
}

.first_item {
  margin-left: 0px;
}
.last_item {
  margin-left: 1%;
}
.buttons {
  margin: 10px 5px 60px 0px;
  float: right;
}
.buttons_toggled {
  display: none;
}
.form-button {
  margin-left: 10px;
  background: #02364c;
  color: #fff;
  border: 0px;
  padding: 5px 10px 5px 10px;
  border-radius: 3px;
  font-weight: 500;
}

.form-button-inactive {
  margin-left: 10px;
  background: #ebeced;
  color: #fff;
  border: 0px;
  padding: 5px 10px 5px 10px;
  border-radius: 3px;
  font-weight: 500;
  cursor: default;
}

.form-button:hover {
  padding: 4px 9px 4px 9px;
  border-radius: 3px;
  margin-left: 10px;
  font-weight: 500;
  border: 1px solid #02364c;
  background: #fff;
  color: #02364c;
}

.select_dataset_heading {
  color: #858585;
  font-size: 16px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  padding: 5px;
}

.small-button {
  margin-left: 10px;
  background: #02364c;
  color: #fff;
  border: 0px;
  font-size: 10px;
  padding: 5px;
  border-radius: 3px;
  font-weight: 500;
}
.small-button-inactive {
  margin-left: 10px;
  background: #dede;
  color: #fff;
  font-size: 10px;
  border: 0px;
  padding: 5px;
  border-radius: 3px;
  font-weight: 500;
}
.small-button:hover {
  padding: 4px;
  font-size: 10px;
  border-radius: 3px;
  margin-left: 10px;
  font-weight: 500;
  border: 1px solid #02364c;
  background: #fff;
  color: #02364c;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #e24809;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #e24809;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.big_input {
  width: 88%;

  background: #fff;
}

.small_input {
  width: 75%;
}

.date_picker {
  color: hsl(0, 0%, 50%);
  width: 75%;
  /* margin-left:55px */
}
.date_picker input[type="text"] {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 3px;
  height: calc(1.5em + 0.5rem + 6px);
  border-radius: 0px 3px 3px 0px;
  height: 38px;
}
/* .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle{
  bottom: 0;
  margin-bottom: 0px;


} */

.contract_list_div {
  clear: both;
  width: 100%;
  transition: 0.5s;
}
.contract_list_div_toogled {
  width: 0%;
  transition: 0.5s;
}

.switch_color {
  color: #02364c;
}

/* 
ol.contract-list::before {
  content: 'Contracts';
   
    padding: 8px 192px;
    background: #e24809;
    color: #fff;
    text-align: center;
    width: 23vw;
    font-size: 14px;
    margin-top: -40px;
    
  
} */

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}
.suggestions_inactive {
  display: none;
}
.suggestions {
  margin-top: 4px;
  width: 88%;
  /* float: right; */
  /* margin-left: 49px; */
  list-style: none;
  position: absolute;
  z-index: 3000;
  background: #fff;
  /* border: #cece; */
  border: 1px solid #ddd;
  margin-right: 0px;
  border-radius: 4px;
}

.suggestions li {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 10px;
  text-align: left;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #deebff;
  /* color: #e24809; */
  cursor: pointer;
}
.inputs {
  width: 100%;
  height: 38px;
  padding: 10px;
  border: 1px solid #f2ae92;
  border-radius: 0 0.25rem 0.25rem 0;
}
.css-yk16xz-control {
  border-color: #f2ae92 !important;
}

.input_div {
  width: 88%;
  margin-left: 12%;
}
.input_div > .plugin {
  width: 100%;
  text-align: left;
}
.input_div_half {
  width: 75%;
}

/* input[type=text] */

.input_div input[type="text"] {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.info_title {
  word-wrap: break-word;
  width: 70%;
  line-height: 13px;
}

.big_input.plugin > div {
  border-radius: 0px 5px 5px 0px;
}

.big_input.plugin {
  /* margin-left: 13%; */
  margin-right: 5%;
  text-align: left;
}

.big_input.plugin.dataset_input {
  margin-right: 0px;
}
.big_input.plugin > div > div {
  border-radius: 2px;
}

.small_input.plugin > div {
  border-radius: 0px 5px 5px 0px;
}

.small_input.plugin {
  text-align: left;
}
.small_input.plugin > div > div {
  border-radius: 2px;
}
/* data tables */

#modal-table-wrapper {
  overflow: auto;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  border-collapse: collapse;
  max-width: 100%;
  border-spacing: 2px;
  border-color: grey;
  overflow-y: auto;
  max-height: 50vh;
  margin-top: 20px;
}
#modal-table-wrapper table tr:first-of-type {
  border-top: none;
}
#modal-table-wrapper table tr:last-of-type {
  border-bottom: 1px solid #c1c3d1;
}

#modal-table-wrapper table {
  border-collapse: collapse;
  min-width: 100%;
  border-spacing: 1px;
}

#modal-table-wrapper table tr {
  border-top: 1px solid #c1c3d1;
  color: #e24809;
  font-weight: normal;
  /* text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); */
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
#modal-table-wrapper table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-left: 1px;
}

#modal-table-wrapper table th {
  /* color: rgb(50, 87, 129); */
  color: #fff;
  background: #e24809;
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  position: sticky !important;
  top: 0;
  z-index: 2;
  white-space: nowrap;
  padding: 6px 20px;
  vertical-align: middle;
}
.flixed-col {
  position: absolute;
  left: 0;
  float: left;
  top: auto;
  width: 120px;
}
#modal-table-wrapper table tr:hover td {
  /* background:#e3ffa3; */
  background: #e3ffa3;
  color: #000;
  /* cursor: pointer; */
}

@media screen and (max-width: 1200px) {
  #main-table-wrapper {
    left: 0;
    right: 0;
    bottom: 0;
    border-collapse: collapse;

    border-spacing: 2px;
    border-color: grey;
    /* overflow-y: auto; */
    max-height: 78vh;
    max-width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  #main-table-wrapper {
    left: 0;
    right: 0;
    bottom: 0;
    border-collapse: collapse;

    border-spacing: 2px;
    border-color: grey;
    /* overflow-y: auto; */
    max-height: 78vh;
    max-width: 100%;
  }
}
@media screen and (min-width: 1400px) {
  #main-table-wrapper {
    left: 0;
    right: 0;
    bottom: 0;
    border-collapse: collapse;

    border-spacing: 2px;
    border-color: grey;

    max-height: 80vh;
    max-width: 100%;
  }
}

@media screen and (min-width: 1600px) {
  #main-table-wrapper {
    left: 0;
    right: 0;
    bottom: 0;
    border-collapse: collapse;

    border-spacing: 2px;
    border-color: grey;

    max-height: 84.5vh;
    max-width: 100%;
  }
}

#both-table-container {
  max-width: 100%;
}
#second-table-wrapper {
  /* overflow: auto; */

  border-collapse: collapse;
  /* max-width: 100%; */
  border-spacing: 2px;
  border-color: grey;
  /* overflow-y: auto; */
  max-height: 86vh;
  /* overflow-x: scroll */
  z-index: -2000;
}
.first-td {
  position: sticky;
}

#main-table-wrapper table tr:first-of-type {
  border-top: none;
}
#second-table-wrapper table tr:first-of-type {
  border-top: none;
}
#main-table-wrapper table tr:last-of-type {
  border-bottom: 1px solid #c1c3d1;
}
#second-table-wrapper table tr:last-of-type {
  border-bottom: 1px solid #c1c3d1;
}
#main-table-wrapper table {
  border-collapse: collapse;
  min-width: 100%;
  border-spacing: 2px;
  background: #fff;
}
#second-table-wrapper table {
  border-collapse: collapse;
  min-width: 99%;
  border-spacing: 2px;
}

#second-table-wrapper table tr {
  border-top: 1px solid #c1c3d1;
  color: #e24809;
  font-weight: normal;
  /* text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); */
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
#main-table-wrapper table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-left: 1px;
}
#second-table-wrapper table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-left: 1px;
}

#main-table-wrapper table th {
  /* color: rgb(50, 87, 129); */
  color: #02364c;
  background: #f1eae7;
  font-size: 1rem;
  position: sticky;
  font-weight: 500;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  top: 0;
  /* z-index: 2; */
  white-space: nowrap;
  /* padding: 6px 39px; */
  padding: 6px 5px;
  vertical-align: middle;
  margin-top: 43px;
  border-right: 1px solid #dedede;
  text-align: center;
}
#second-table-wrapper table th {
  /* color: rgb(50, 87, 129); */
  color: #fff;
  background: #2fbabe;
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  top: 0;
  /* z-index: 2; */
  white-space: nowrap;
  /* padding: 6px 39px; */
  padding: 6px 5px;
  vertical-align: middle;
  margin-top: 43px;
}
/* #main-table-wrapper table th:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  top: 100%;
  background: #ccc;
  content: '';
} */

/* #second-table-wrapper table th:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  top: 100%;
  background: #ccc;
  content: '';
} */
.asc,
.desc {
  font-size: 12px;
}
.hidden {
  display: none;
}

.showArraow {
  display: block;
}

#region_wise_chk {
  position: unset !important;
}
.checkboxText {
  margin-left: 5px;
}
#main-table-wrapper table th.contract-price {
  background: #e24809;
  color: #fff;
}
#second-table-wrapper table th.contract-price {
  background: #e24809;
  color: #fff;
}

#main-table-wrapper table th.frozen {
  position: absolute;
}
#main-table-wrapper table td.frozen {
  position: absolute;
}

#main-table-wrapper table tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
#second-table-wrapper table tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
#main-table-wrapper table tr {
  border-top: 1px solid #c1c3d1;
  color: #02364c;
  /* text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); */
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

#second-table-wrapper table tr {
  border-top: 1px solid #c1c3d1;
  color: #e24809;
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
#main-table-wrapper table tbody tr:first-of-type td {
  padding-top: 8px;
}
#second-table-wrapper table tbody tr:first-of-type td {
  padding-top: 8px;
}
#main-table-wrapper table td {
  /* background: #FFFFFF; */
  font-weight: 500;
  font-size: 11px;
  /* text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1); */
  white-space: nowrap;
  padding: 6px 17px 6px 16px;
  vertical-align: middle;
  border-right: 1px solid rgb(27, 55, 88, 0.2);
  border-left: 1px solid rgb(27, 55, 88, 0.2);
  height: 30px;
}
#second-table-wrapper table td {
  /* background: #FFFFFF; */
  font-weight: 500;
  font-size: 11px;
  /* text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1); */
  white-space: nowrap;
  padding: 6px 17px 6px 16px;
  vertical-align: middle;
  border-right: 1px solid rgb(27, 55, 88, 0.2);
  border-left: 1px solid rgb(27, 55, 88, 0.2);
  height: 30px;
}
.text-center {
  text-align: center;
}
#main-table-wrapper table td.contract-price {
  background: rgb(241, 234, 231);
  color: #1b3758;
  border-right: 1px solid rgb(27, 55, 88, 0.2);
  border-left: 1px solid rgb(27, 55, 88, 0.2);
}
#second-table-wrapper table td.contract-price {
  background: rgb(114, 236, 232, 0.2);
  color: #1b3758;
  border-right: 1px solid rgb(27, 55, 88, 0.2);
  border-left: 1px solid rgb(27, 55, 88, 0.2);
}

#main-table-wrapper table tr:hover td {
  background-color: rgb(182 183 183);
  color: #000000;
}

.tableWhite td {
  background: #fff;
}
.sample_class td {
  /* background: #e3ffa3; */
  background-color: rgba(227, 255, 163, 0.6) !important;
  color: #000;
}
#second-table-wrapper table tr:hover td {
  /* background:#e3ffa3; */
  background-color: rgba(227, 255, 163, 0.6);
  color: #000;
  /* cursor: pointer; */
}

::-webkit-scrollbar {
  margin-left: 1px;
  width: 5px;
  height: 0px;
}

::-moz-scrollbar {
  margin-left: 1px;
  width: 5px;
  height: 0px;
}

:-moz-scrollbar-thumb {
  margin-top: 2px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #e24809;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb {
  margin-top: 2px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #e24809;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.product_counter {
  width: 100%;
  height: 54px;
  /* border-top: 1px solid #C1C3D1; */
  float: right;
  text-align: center;
  /* top: 29px; */
  /* margin-top: -4px;
  padding-top: 12px; */
  color: #02364c;
  font-weight: 500;
}

.update_info {
  width: 15vw;
  transition: 0.5s;
  /* height: 85%; */
  position: absolute;
  /* top: 9.4vh; */
  /* top: 102px; */
  top: 60px;
  bottom: 0;
  left: 85vw;
  background: #fff;
  /* border-left: 1px solid #ddd; */
  border-top: 1px solid #ddd;
  /* box-shadow: 8px 0px 5px 10px #eaa78e; */
}
.update_info_toggled {
  width: 2vw;

  transition: 0.5s;
  height: 100%;
  position: absolute;

  top: 60px;
  bottom: 0;
  left: 100vw;
  background: #fff;

  border-left: 1px solid #ddd;
}

.zindex_3 {
  z-index: 3;
}
.zindex_2 {
  z-index: 2;
}

li.highLightLi.listItems {
  font-size: 12px;
  display: inline-block;
}
li.nonHiglighted.listItems {
  font-size: 12px;
  display: inline-block;
}
ol.contract-list_toggled {
  display: none;
}
ol.contract-list {
  list-style: none;
  width: 100%;
  font-size: 10px;
  /* font-size: 13px; */
  background: #fff;
  z-index: 2;
  /* margin-top: 42px; */
  /* padding-top:10vh;    */
}
ol.status-list_toggled {
  display: none;
}
ol.status-list {
  list-style: none;
  width: 100%;
  font-size: 10px;
  background: #fff;
  /* z-index: 5000; */
  /* bottom: 0; */
  /* top: 50vh; */
  /* margin-top: 16vh; */
}
.contract_status_container {
  width: 100%;
  height: 100vh;
  border-left: 1px solid #e8e8e8;
  margin-left: -1px;
}
/* ol.status-list::before, ol.contract-list::before {
 
  
  left: 0px;
  padding: 0.5em 10em;
  content: 'Updates';
  background: #1B3758;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 14px;
} */
.update_info_header {
  line-height: 20px;
}
ol.contract-list > li,
ol.status-list > li,
div.updateInfoDiv > li {
  line-height: 10px;
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;

  /* white-space: nowrap; */
}
ol.contract-list > li > span,
ol.status-list > li > span,
div.updateInfoDiv > li > span {
  display: inline-block;
  text-align: left;
  float: left;
}

.savedSearchDiv {
  max-height: 80vh;
  overflow-y: auto;
}

ol.contract-list > li > span:last-child,
ol.status-list > li > span:last-child,
div.updateInfoDiv > li > span:last-child {
  text-align: left;
  float: right;
}

ol.contract-list > li:first-of-type,
ol.status-list > li:first-of-type {
  background: #02364c;
  padding: 12px 15px;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin-left: -1px;
  width: 101%;
}

.pagination_container {
  padding: 10px;
  /* border: 1px solid; */
  height: 42px;
  border-left: 1px solid #cdcdcd;
  text-align: center;
  float: right;
  width: 50%;
}

.pagination_container .disabled_link {
  pointer-events: none;
  cursor: default;
  /* color:#1B3758 */
  color: #c1c3d1;
}
.pagination_container span {
  color: #1b3758;
}

.pagination_container span:hover {
  color: #e24809;
  cursor: pointer;
}

.pagination_style {
  top: 70px;
  right: -5px;
  font-size: 15px;
  /* left: 65vw; */
  position: absolute;
  width: 32vw;
  height: 43px;
}

.spinning_pill {
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  z-index: -3;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinning_pill > img {
  width: 50px;
}
.loaded_data {
  width: 100%;
  height: 100;
}
.no_data_found,
.no_saved_search {
  /*margin-top: 30%; */
  /* height: 200px; */
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  background: #fff;
  font-size: 20px;
  color: #e24809;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no_saved_search {
  font-size: 15px !important;
}

.spinning_pill_hidden {
  display: none;
}

.dimmed_sort_icon {
  color: #ccc;
}

.clickable {
  cursor: pointer;
}
.discount {
  color: #ff8383;
}

.valid_only_checkbox,
.region_wise_checkbox {
  margin-top: 10px;
  color: #e24809;
  text-align: left;
}
.valid_only_checkbox label,
.region_wise_checkbox label {
  margin-top: 3px;
  margin-left: 20px;
}
.valid_only_checkbox label:hover,
.region_wise_checkbox label:hover {
  color: #2bb7bb;
  cursor: pointer;
}

.contract_status_container > div::before {
  font-weight: 900;
  position: absolute;
  top: 50vh;
  left: -33px;
  font-family: "Font Awesome 5 Free";
  content: "\f65d";
  transform: translateY(-50%);
  padding: 30px 100px 30px 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
  color: #02354a;
  z-index: -3000;
}

.contract_status_container > div::after {
  z-index: -3000;
  content: " ";
  background: #fff;
  position: absolute;
  top: 50vh;
  left: 0;
  transform: translateY(-50%);
  height: 90px;
  width: 100px;
}

.contract_status_container_toogled > div::before {
  font-weight: 900;
  position: absolute;
  top: 50vh;
  left: -33px;
  font-family: "Font Awesome 5 Free";
  content: "\f65e";
  font-size: 18px;
  transform: translateY(-50%);
  padding: 30px 100px 30px 10px;
  background: #02354a;
  border: 1px solid #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
  z-index: -1;
  color: #ccc;
}

.contract_status_container_toogled > div::after {
  z-index: 0;
  content: " ";
  background: #fff;
  position: absolute;
  top: 50vh;
  left: 0;
  transform: translateY(-50%);
  height: 90px;
  width: 100px;
}

.infotag {
  cursor: pointer;
}

.modal_title {
  background: #e24809;
  color: #fff;
}
.modalBody {
  background: #fff;
  max-height: 60%;
  max-width: 90vw;
  border-top: 1px solid #c1c3d1;
  border-bottom: 1px solid #c1c3d1;
  color: #e24809;
  font-weight: normal;
  font-size: 14px;
  /* text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); */
}
.help_content {
  max-height: 100%;
}

.brand {
  color: #e24809;
  font-weight: bold;
  font-size: 14px;
}

.brand_italic {
  font-style: italic;
  color: #e24809;
  font-weight: bold;
  font-size: 14px;
}

.form_alert > p {
  max-height: 100%;
  text-align: center;
  line-height: 41px;
  margin-top: 9px;
}
.main_modal {
  max-height: 70vh;
  max-width: 90vw;
}

.description-table {
  width: 50%;
}
.modal_substance_header {
  font-size: 1.3rem;
  color: #02364c;
  margin: 24px;
  display: inline-block;
}

.description_chart {
  /* width: 100%;
  padding: 20px;
  display: block;
  margin: auto auto;
   */
  text-align: center;
}
/* .chart_info {
  width: 100%;
  text-align: center;
}
.chart_info>div{
  width:32%;
  display: inline-block;
} */
.header_second_line > td {
  text-align: right;
}

.chart_info {
  display: flex;
  margin: 0 24px 24px 24px;
  justify-content: center;
}

.chart_info > div {
  width: 40%;
}

.chart_info > div:first-child {
  margin-right: 24px;
}

.chart_info ul {
  list-style-type: none;
  margin-bottom: 0;
}

.chart_info ul > li {
  padding: 2px 0;
  display: flex;
  align-items: center;
}

ul.area-colors li span {
  font-weight: bold;
}

.chart_info ul.chart-values > li > span:first-child {
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 24px;
  width: 30%;
}

div.active-pill {
  background: #efefef;
  border-radius: 4px;
  padding: 2px 4px;
}

div.patient {
  background: #3366cc;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 4px;
  margin-right: 24px;
}

div.prescription {
  background: #e24809;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 4px;
  margin-right: 24px;
}

.o-hidden {
  overflow: hidden;
}

/* .modal_container {
  
} */

.capitalize {
  text-transform: capitalize;
}

.not_clickable {
  color: #047cae !important;
  cursor: default !important;
}

.not_clickable:hover {
  color: #047cae !important;
  cursor: default !important;
}

#container {
  height: 400px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highLightLi {
  background: #cece;
}

.higlightRow {
  /* background: #e3ffa3; */
  background: #e3ffa3;
  color: #000;
  /* cursor: pointer; */
}

.notification_text {
  color: #1a417b;
  font-weight: 600;
}

#table_1 {
  margin-right: -1px;
}

#sales_table_1 {
  margin-right: -1px;
  z-index: 3;
}

.tutorial-skip,
.tutorial-read {
  display: none !important;
}

/* .headcol {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  /*only relevant for first row*/
/* margin-top: -1px; */
/*compensate for top border}*/
/* 


th#county_name,th#substance, th#company, th#name,th#strength, th#size  {
  position: absolute;
} */

/* table#table_1 tr:hover {
  background-color:#e3ffa3;
}
table#table_1 tr:hover {
  background-color:#e3ffa3;
}
#table_1 td:hover::before {
  background-color: #e3ffa3;
  content:'\00a0';
  position: absolute;
  width: 10000px;
  height: 31px;
  z-index: -4000;
}
td {
  position: relative;
} */

.imframe {
  border: 0px;
  overflow: hidden;
}

p.i-text > span {
  font-style: italic;
  color: #e24809;
  font-weight: bold;
  font-size: 13px;
}
p.n-text > span {
  color: #e24809;
  font-weight: bold;
  font-size: 13px;
}
.padding-left {
  padding-left: 5px;
}
.padding-left-10 {
  padding-left: 10px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-extra {
  padding-right: 30px;
}

.padding-right {
  padding-right: 5px;
}

.padding-right-extra {
  padding-right: 30px;
}
.pading-left-right {
  padding-left: 5px;
  padding-right: 5px;
}

.customeTheme {
  color: #fff !important;
  background-color: #2fbabe !important;
}

div#form_buttons {
  background: transparent !important;
  padding-bottom: 33px;
}

div#form_buttons > button {
  float: right;
}

div#dropdown_help {
  margin-top: 11px;
}

a.dropdown-toggle.nav-link {
  color: #737576;
  /* color: #2fbabe; */
  /* text-shadow: 0px 1px #aeaeae; */
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

button.dropdown-item {
  color: #737576;
  /* color: #2fbabe; */
  /* text-shadow: 0px 1px #aeaeae; */
  text-align: right;
}
a.dropdown-toggle.nav-link:hover,
button.dropdown-item:hover {
  color: #e24809;
}

.promptMinWidth {
  min-width: 300px;
}

.tutorial-curtain {
  position: fixed;
  width: 150vw;

  height: 150vh;
  background: #000;
  top: -100px;

  opacity: 0.45;
  z-index: 2000;
  transition: 3s;
}

.zero_width {
  width: 0vw !important;
}

.promptMaxWidth {
  max-width: 300px;
}

/*timeline code*/
.timeline {
  width: 50%;
  margin: 40% 0px 0px 20%;
}

.timeline > ul > li > span::first-letter {
  text-transform: capitalize;
}

/* START TIMELINE 
the width of the list parent is optional 
if you set this width 
A hover sample effect is added as well as an active state*/

.timeline .base-timeline {
  list-style-type: none;
  counter-reset: number; /* number 2021*/
  position: relative;
  display: block;
  z-index: 2;
  width: 75%; /* change or remove*/
}
.show {
  display: inline-block;
}
.hide {
  display: none;
}

.float_right {
  float: right;
}

.base-timeline::before {
  content: "";
  width: 100%;
  border-top: 2px solid steelblue;
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: -1;
}

/* set width of time-line this can be px, percentage or other unit
3 is the number of list items minus 1 when using percentage
*/
.base-timeline__item {
  position: relative;
  display: inline-block;
  width: calc(100% / 3 - 15px); /* change width */
}

.base-timeline__item::before {
  display: flex;
  justify-content: center;
  align-items: center;
  counter-increment: number; /* number -1*/
  content: counter(number) "";
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: steelblue;
  color: white;
  font-weight: bold;
  transition: all 0.6s ease-in-out;
  box-sizing: border-box;
}

/* modifier with use of the data-year attribute */
.base-timeline__item--data::before {
  content: attr(data-year);
  width: 60px;
  height: 60px;
}

/* hover element */
.base-timeline__item:hover::before {
  background-color: rgba(225, 114, 114, 0.9);
  transform: scale(2);
}

.base-timeline__item--active::before {
  background-color: rgba(225, 114, 114, 0.9);
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.3);
}

.base-timeline__item:last-child {
  width: 0;
  margin-left: 10px;
}

/* summary text is optional and can be anything */

.base-timeline__summary-text {
  position: absolute;
  bottom: -2em;
  left: 5px;
}

.time_line_curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  opacity: 0.15s linear;
}

.__react_component_tooltip.type-light.place-top:after {
  border-top-color: #2fbabe;
}

div#window {
  width: 50%;
  height: 50%;
  margin: auto auto;
  background: #f8f9fa;
  position: absolute;
  top: 25%;
  left: 50;
  left: 25%;
  z-index: 10;
  padding: 30px;
  border: #fff !important;

  border-radius: 5px;
}

#tutorial_inner {
  border: 1px solid #1a417b !important;
  background: #2fbabe !important;
  border-radius: 5px;
  color: #fff;
  text-align: left;
  width: 50%;
  height: 50%;

  /* margin: 10px 10px 10px 10px; */
}

@keyframes curtain-animation {
  from {
    background: transparent;
  }
  to {
    background: rgba(0, 0, 0, 0.7);
  }
}
@keyframes curtain-end-animation {
  from {
    background: rgba(0, 0, 0, 0.7);
  }
  to {
    background: transparent;
  }
}
.tutorial-element {
  position: relative;
  background: #fff;
  z-index: 2010;
}

.tutorial-prompt {
  position: absolute;
  background: #fff;
  z-index: 2009;
  padding: 20px 20px;

  border-radius: 4px;
}

.tutorial-inner {
  padding: 10px 15px;
  background-color: #2fbabe;
  border-radius: 4px;
  margin-top: 10px;
  color: #fff;
  text-align: left;
}

/*  */

.tutorial-inner {
  padding: 20px 20px;
  background: #2fbabe;
  border-radius: 4px;
  margin-top: 10px;
  color: #fff;
  text-align: left;
}
.tutorial-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 22px;
  color: #ffffff;
  font-weight: 500;
}
.tutorial-btn {
  border: solid 1px #000000;
  cursor: pointer;
  color: #000000;
  background: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 13px;
  text-transform: capitalize;
  display: inline-block;
}
.tutorial-prev {
  margin-right: 15px;
}
.tutorial-desc {
  font-size: 18px;
  text-transform: none;
}
p.tutorial-desc {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.tutorial-footer {
  margin-top: 8px;
  padding-top: 8px;
  border-top: solid 1px rgba(255, 255, 255, 0.5);
  font-size: 11px;
}
.tutorial-skip {
  text-decoration: underline;
  float: right;
  cursor: pointer;
}
.tutorial-read {
  float: right;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  width: 22px;
  height: 16px;
  line-height: 22px;
  top: -1px;
  position: relative;
}

.__react_component_tooltip.type-light.border.place-top:before {
  border-top: 8px solid #44c1c5 !important;
}

.__react_component_tooltip.type-light.place-top:after {
  border-top-color: #37b4b8 !important;
  border-top-style: solid;
  border-top-width: 6px;
}

iframe#iframeId {
  width: 75px;
  height: 36px;
  border: 0px;
  margin-left: 16px;
  margin-right: 2px;
  padding-top: 6px;
}

#notfound {
  position: relative;
  height: 100vh;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound .notfound-404 {
  position: relative;
  height: 150px;
  line-height: 150px;
  margin-bottom: 25px;
}

.notfound .notfound-404 h1 {
  font-size: 186px;
  font-weight: 900;
  margin: 0px;
  text-transform: uppercase;

  -webkit-text-fill-color: #37b4b8;
  background-size: cover;
  background-position: center;
}

.notfound h2 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  line-height: 3;
}

.notfound p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.notfound a {
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  border: none;
  background: #5c91fe;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 1px;
  margin-top: 15px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound a:hover {
  background: #fff;
  color: #5c91fe;
  text-decoration: none;
  border: 1px solid #5c91fe;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 1px;
  margin-top: 15px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
/*down part will be removed later */
/* 
.float-container {
  border: 0px solid #fff; */
/* position: absolute; */
/* overflow-y: scroll; */
/* max-height: 86vh;
  overflow-y: scroll;
} */

/* .float-child {
  width: 50%;
  height: 87vh;
  float: left;
} 

#blue table th{
  position: sticky;
}
.blue {
  max-width: 100%;
  overflow-x: scroll;
}
#blue::-webkit-scrollbar {
  width: 10px;
} */

/* #blue table td.frozen {
 
  position: sticky;
  
} */
/* .blue::-webkit-scrollbar {
  -webkit-appearance: none;
}

.blue::-webkit-scrollbar:vertical {
  width: 11px;
}

.blue::-webkit-scrollbar:horizontal {
  height: 11px;
}

.blue::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
/* background-color: rgba(0, 0, 0, .5);
}
 */

.css-1okebmr-indicatorSeparator,
.css-tlfecz-indicatorContainer,
.css-tlfecz-indicatorContainer {
  display: none !important;
}

.react-tooltip-lite {
  background: #2fbabe !important;
  width: 250px !important;
  color: white;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
  padding: 15px 8px 0px 8px !important;
  line-height: 1.5rem;

  opacity: 0.9;
  transition: opacity 0.3s ease-out;

  z-index: 1;
}
.ip-list {
  padding-bottom: 0px !important;
}
.react-tooltip-lite-arrow {
  border-color: #2fbabe;
}

.tip-list li {
  list-style: none;
}

.tabContent {
  width: 95%;
}
.tabTitle {
  width: 5%;
  writing-mode: vertical;
  text-orientation: upright;
}
.rwt__tab {
  position: relative;
  height: 300px;
  width: 30px;
}
.rwt__tab button {
  width: 20px;
  border: 1px solid #ccc;
}
.rwt__tablist[aria-orientation="vertical"] {
  margin-right: 0rem !important;
}

span.abbreviation {
  width: 100%;
  font-size: 11px;
  color: #e24809;
  font-weight: 600;
  padding-left: 20px;
}
span.abbreviation_right {
  width: 100%;
  font-size: 11px;
  color: #e24809;
  font-weight: 600;
  padding-left: 20px;
}

ul.modal_option_names {
  list-style-type: none;
  margin: auto auto !important;
  overflow: hidden;

  display: inline-block;
}
ul.modal_option_names > li {
  float: left;
  background-color: #fff;
  color: #d6805d;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
}

.option_detail {
  width: 90%;
  margin: auto;
  margin-top: -25px;
}

#substanceTable tfoot,
#productTable tfoot,
#substanceTable tfoot th,
#productTable tfoot th,
#substanceTable tfoot td,
#productTable tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: rgb(241, 234, 231);
  color: #02364c;
  z-index: 1;
  padding: 7px 17px 7px 16px;
}

#main-table-wrapper table tfoot tr:hover td {
  background-color: rgb(241, 234, 231);
  color: #02364c;
}

.single_search_container {
  font-size: 11px;
  border: 1px solid #ea7b48;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 0px 0px 5px 0px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.single_search_container:hover {
  cursor: pointer;
}
.single_search_title {
  background: #ea7b48;
  padding: 4px;
  text-align: center;
  font-weight: 700;
  color: #fff;
}
.show-selected-item {
  background: #ffffff;
  display: inline-block;
  color: #02364c;
  font-weight: 600;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin: 5px;
  border: 0px solid #ea7b48;
  box-shadow: 1px 6px 10px #6f361e;
}

.clickable_cursor {
  cursor: pointer;
}

.checkbox_container {
  width: 25vw;
  margin-left: 20px;
  margin-top: 10px;
  display: inline-block;
  padding-bottom: -18px;
  /* height: 40px; */
  margin-bottom: -20px;
  padding-bottom: -15px;
}

.form-check-input {
  position: unset !important;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

button#save_search {
  float: right;
}

.wrap_title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.inputFields .type_to_select {
  position: relative;
}

.inputFields .type_to_select::after {
  display: flex;
  align-items: center;
  padding-right: 28px;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  color: hsl(0, 0%, 70%);
  height: 38px;
  /* font-style: italic; */
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  /* background: rgba(0,0,0,.3); */
  content: attr(data-id);
}

.inputFields .only_prod {
  position: relative;
}

.inputFields .only_prod::after {
  display: flex;
  align-items: center;
  margin-right: 28px;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  color: hsl(0, 0%, 70%);
  height: 38px;
  /* font-style: italic; */
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 14px;
  /* background: rgba(0,0,0,.3); */
  content: attr(data-id);
}
.select__value-container {
  background: transparent;
  z-index: 1;
}

.reset-pass-form {
  width: 75%;
  margin: auto;
}

#message {
  display: none;
  color: #1a427b;
  position: relative;
  padding: 2px 20px 20px 35px;
  font-size: 14px;
}

#message p {
  text-align-last: left;
  margin-left: 60px;
  margin-bottom: 0rem !important;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: #02364c;
}

.valid:before {
  position: relative;
  left: -5px;
  content: "✔";
  font-size: 10px;
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left: -5px;
  content: "✖";
  font-size: 10px;
}

.login-box {
  width: 400px;
  height: 600px;
  margin: 200px auto;
}
