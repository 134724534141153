/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
  margin-right: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid#9bb0b8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 40px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 1.5px;
  background-color: #9bb0b8;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  /* border: 1px solid #9bb0b8;  */
}

input:checked + .slider {
  background-color: #02364c;
  border: 1px solid #02364c;
}

input:checked + .slider:before {
  background-color: white;
}

input:focus + .slider {
  box-shadow: 0 0 1px #151719;
}

input:hover + .slider {
  box-shadow: 0 0 2px #02364c;
}


input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}
